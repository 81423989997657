var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tile" }, [
    _c("i", { class: _vm.icon }),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.title))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }