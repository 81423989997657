<template>
  <div class="tile">
    <i :class="icon"></i>
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: [String, Number],
    icon: String
  },
  data() {
    return {};
  }
};
</script>
